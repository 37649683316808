<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- <b-img
        :style="{
          filter: skin == 'dark' ? 'brightness(0) invert(1)' : '',
        }"
        class="mr-2 ml-50"
        width="78px"
        height="29px"
        src="v_main_logo.png"
        alt="logo"
      /> -->
      <b-img
        v-if="isNavMenuHidden"
        :style="{
          filter: false ? 'brightness(0) invert(1)' : '',
          width: '2rem',
        }"
        class="ml-50 mr-1"
        fluid
        :src="logo_sq"
        alt="logo"
      />
      <b-button
        v-if="menu_list.includes('agent_dashboard')"
        @click="selected_button = 'statistics'"
        :to="'/agent_dashboard'"
        v-b-tooltip.hover.v-secondary
        v-b-tooltip.hover.bottom="lang('t_dashboard')"
        :style="{ opacity: selected_button == 'statistics' ? 0.5 : 1 }"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="
          selected_button == 'statistics' ? 'secondary' : 'flat-secondary'
        "
        class="btn-icon rounded-circle"
      >
        <feather-icon size="20" icon="GridIcon" />
      </b-button>
      <b-button
        v-if="menu_list.includes('crm')"
        @click="selected_button = 'crm'"
        :to="'/crm'"
        v-b-tooltip.hover.v-secondary
        v-b-tooltip.hover.bottom="'CRM'"
        :style="{ opacity: selected_button == 'crm' ? 0.5 : 1 }"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="selected_button == 'crm' ? 'secondary' : 'flat-secondary'"
        class="btn-icon rounded-circle ml-25"
      >
        <feather-icon size="20" icon="UsersIcon" />
      </b-button>
      <b-button
        v-if="menu_list.includes('calendar')"
        @click="selected_button = 'apps-calenda'"
        :to="'/calendar'"
        v-b-tooltip.hover.v-secondary
        v-b-tooltip.hover.bottom="lang('t_calendar')"
        :style="{ opacity: selected_button == 'apps-calenda' ? 0.5 : 1 }"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="
          selected_button == 'apps-calenda' ? 'secondary' : 'flat-secondary'
        "
        class="btn-icon rounded-circle ml-25"
      >
        <feather-icon size="20" icon="CalendarIcon" />
      </b-button>
      <b-button
        v-if="menu_list.includes('phone_book')"
        @click="selected_button = 'phone-book'"
        :to="'/phone_book'"
        v-b-tooltip.hover.v-secondary
        v-b-tooltip.hover.bottom="lang('t_phoneBook')"
        :style="{ opacity: selected_button == 'phone-book' ? 0.5 : 1 }"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="
          selected_button == 'phone-book' ? 'secondary' : 'flat-secondary'
        "
        class="btn-icon rounded-circle ml-25"
      >
        <feather-icon size="20" icon="BookIcon" />
      </b-button>
      <!-- <b-button
        v-if="menu_list.includes('evaluated_records')"
        @click="selected_button = 'evaluated-records'"
        :to="'/evaluated_records'"
        v-b-tooltip.hover.v-secondary
        v-b-tooltip.hover.bottom="lang('t_evaluatedRecords')"
        :style="{ opacity: selected_button == 'evaluated-records' ? 0.5 : 1 }"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="
          selected_button == 'evaluated-records'
            ? 'secondary'
            : 'flat-secondary'
        "
        class="btn-icon rounded-circle ml-25"
      >
        <feather-icon size="20" icon="CheckSquareIcon" />
      </b-button> -->
      <b-button
        v-if="menu_list.includes('external_chat')"
        @click="selected_button = 'external-chat'"
        :to="'/external_chat'"
        v-b-tooltip.hover.v-secondary
        v-b-tooltip.hover.bottom="lang('t_externalChat')"
        :style="{ opacity: selected_button == 'external-chat' ? 0.5 : 1 }"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :variant="
          selected_button == 'external-chat' ? 'secondary' : 'flat-secondary'
        "
        class="btn-icon rounded-circle ml-25"
      >
        <feather-icon size="20" icon="MessageCircleIcon" />
      </b-button>
      <!-- <b-form-checkbox
        v-model="menu.hidden"
        class="custom-control-primary"
        name="dont_connect_machines"
        switch
      >
        <span class="switch-icon-left">
          <feather-icon icon="CheckIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="XIcon" />
        </span>
      </b-form-checkbox> -->
    </div>

    <!-- nav class'ı silindi -->
    <b-navbar-nav class="align-items-center ml-auto">
      <b-button-group v-if="status.length > 0" class="mr-1" size="sm">
        <!-- <b-button :variant="status_color.split('-')[1]">
          {{ statu_timer_text }}
        </b-button> -->
        <b-dropdown
          size="sm"
          v-b-tooltip.hover="{ variant: status_color.split('-')[1] }"
          v-b-tooltip.hover.bottom="statu_timer_text"
          :disabled="!is_registered"
          v-if="selected_status.changeable == 1"
          v-model="selected_status"
          :text="selected_status.display_name"
          :variant="status_color"
          :class="[`bg-light-${status_color.split('-')[1]}`, 'ml-auto']"
        >
          <b-dropdown-item
            v-for="(item, i) in status.filter(
              (e) => e.changeable == 1 && e.display_name != 'Not Answering'
            )"
            :key="i"
            @click="on_status_change(item)"
          >
            {{ item.display_name }}
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          size="sm"
          v-else
          v-model="selected_status"
          :text="selected_status.display_name"
          :variant="status_color"
          :class="[`bg-light-${status_color.split('-')[1]}`, 'ml-auto']"
          disabled
        >
          <b-dropdown-item
            v-for="(item, i) in status.filter((e) => e.changeable == 0)"
            :key="i"
            @click="on_status_change(item)"
          >
            {{ item.display_name }}
          </b-dropdown-item>
        </b-dropdown>
      </b-button-group>
      <dark-Toggler
        v-b-tooltip.hover="{ variant: 'secondary' }"
        v-b-tooltip.hover.bottom="
          isDark ? lang('t_lightMode') : lang('t_darkMode')
        "
        class="d-none d-lg-block"
      />
      <!-- <notification-dropdown
        v-b-tooltip.hover="{ variant: 'secondary' }"
        v-b-tooltip.hover.bottom="lang('t_notifications')"
        class="d-none d-lg-block"
      /> -->
      <b-dropdown
        @hidden="readNotifications"
        variant="flat-secondary"
        no-caret
        :right="$store.state.appConfig.isRTL"
        toggle-class="px-50 py-50"
        v-b-tooltip.hover.top="lang('t_notifications')"
        v-b-tooltip.hover.v-secondary
        menu-class="dCustomClass"
        boundary="viewport"
        dropleft
      >
        <template #button-content>
          <feather-icon
            :badge="notifications.filter((e) => e.Read != true).length"
            badge-classes="bg-danger"
            class="text-body"
            icon="BellIcon"
            size="21"
          />

          <!-- <b-icon icon="bell"></b-icon> -->
        </template>

        <b-dropdown-form
          style="width: 30rem; max-height: 30rem; overflow-y: auto"
        >
          <!-- Header -->
          <li class="dropdown-menu-header">
            <div class="d-flex mb-1">
              <h4 class="notification-title mb-0 mr-auto">
                {{ lang("t_notifications") }}
              </h4>
              <b-badge pill variant="light-primary">
                {{
                  notifications.filter((e) => e.Read != true).length +
                  " " +
                  lang("t_new")
                }}
              </b-badge>
            </div>
          </li>

          <!-- <div
            class="d-flex align-items-center"
            :key="i"
            v-for="(item, i) in notifications"
          >
            <div>{{ item }}</div>
          </div> -->
          <div
            class="border-top"
            v-for="(notification, i) in notifications"
            :key="i"
          >
            <b-media class="my-50">
              <template #aside>
                <b-avatar size="32" :variant="`light-${notification.Variant}`">
                  <i class="bi font-medium-3" :class="notification.Icon"></i>
                </b-avatar>
              </template>
              <b-media-body>
                <div class="mb-0 d-flex">
                  <span class="font-weight-bolder">
                    {{ notification.Title }}
                  </span>
                  <span class="text-muted ml-auto">{{
                    notification.Time
                  }}</span>
                </div>
                <small class="notification-text">{{ notification.Text }}</small>
              </b-media-body>
            </b-media>
          </div>
        </b-dropdown-form>
      </b-dropdown>

      <b-nav-item-dropdown
        v-if="username"
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ `${name} ${surname}` }}
            </p>
            <span class="user-status text-muted">{{ role }}</span>
          </div>
          <b-avatar
            :variant="is_registered ? 'light-success' : 'light-secondary'"
          >
            <!-- <template #badge>
              <feather-icon :icon="is_registered ? 'WifiIcon' : 'WifiOffIcon'"
            /></template> -->
            {{
              username.substr(0, 1).toUpperCase() +
              username.split(".")[1].substr(0, 1).toUpperCase()
            }}
          </b-avatar>
        </template>
        <div class="mx-50 mb-50">
          <b-dropdown-item
            :to="'/profile'"
            size="sm"
            variant="flat-primary"
            block
            class="px-0"
            link-class="px-50"
          >
            <div class="d-flex align-items-center text-truncate">
              <b-avatar
                :text="` ${
                  username.substr(0, 1).toUpperCase() +
                  username.split('.')[1].substr(0, 1).toUpperCase()
                }`"
                size="sm"
                :variant="'light-primary'"
              >
              </b-avatar>
              <span class="ml-50 text-truncate" style="font-size: 14px">
                {{ `${name} ${surname}` }}</span
              >
            </div>
          </b-dropdown-item>
        </div>

        <b-dropdown-divider />

        <!-- <div class="d-flex align-items-center py-25">
          <b-form-checkbox
            v-if="user_agent"
            class="custom-control-primary ml-1"
            name="check-button"
            switch
            :checked="user_agent.isRegistered()"
          >
            <span class="switch-icon-left">
              <feather-icon class="mt-25" icon="WifiIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon class="mt-25" icon="WifiOffIcon" />
            </span>
          </b-form-checkbox>
          <span>{{
            is_registered ? lang("t_connected") : lang("t_disconnected")
          }}</span>
        </div> -->

        <!-- <b-dropdown-item @click="login" link-class="d-flex align-items-center">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span class="ml-50">Register</span>
        </b-dropdown-item>
        <b-dropdown-item
          @click="unregister"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span class="ml-50">UnRegister</span>
        </b-dropdown-item> -->
        <!-- <div class="d-flex align-items-center py-25">
          <b-form-checkbox
            @change="
              () => {
                skin = isDark ? 'semi-dark' : 'dark';
              }
            "
            class="custom-control-primary ml-1"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon
                class="mt-25"
                :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
              />
            </span>
            <span class="switch-icon-right">
              <feather-icon
                class="mt-25"
                :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
              />
            </span>
          </b-form-checkbox>
          <span>{{
            skin != "dark" ? lang("t_dayMode") : lang("t_nightMode")
          }}</span>
        </div> -->
        <!-- 
        <b-dropdown-item
          @click="skin = isDark ? 'semi-dark' : 'dark'"
          link-class="d-flex align-items-center"
        >
          <feather-icon size="16" :icon="`${isDark ? 'Sun' : 'Moon'}Icon`" />

          <span class="ml-50">{{
            skin == "dark" ? "Aydınlık Mod" : "Karanlık Mod"
          }}</span>
        </b-dropdown-item> -->

        <!-- <b-dropdown-item
          v-if="other_permission.includes('agent_list')"
          @click="
            () => {
              get_live_agent();
              modal_agent_list = true;
            }
          "
          link-class="d-flex align-items-center py-25"
        >
          <feather-icon size="16" icon="UsersIcon" class="mr-50" />
          <span class="ml-50">{{ lang("t_agentList") }}</span>
        </b-dropdown-item> -->
        <b-dropdown-item
          v-b-modal.modal-settings
          link-class="d-flex align-items-center py-25"
        >
          <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
          <span class="ml-50">{{ lang("t_settings") }}</span>
        </b-dropdown-item>
        <b-dropdown-divider />

        <b-dropdown-item
          @click="logout"
          link-class="d-flex align-items-center py-25"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span class="ml-50">{{ lang("t_logOut") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <!-- <b-modal
      size="lg"
      static
      :title="lang('t_agentList')"
      hide-footer
      scrollable
      centered
      v-model="modal_agent_list"
    >
      <div>
        <b-form-input
          v-model="searchQuery"
          class="d-inline-block"
          :placeholder="lang('t_search')"
        />
      </div>
      <b-table
        style="max-height: 50vh; overflow-y: auto"
        class="mt-1"
        :items="agent_list"
        :fields="agent_list_fields"
        striped
        responsive="sm"
        :filter="searchQuery"
        :sortBy="'Username'"
      >
        <template #cell(Username)="data">
          <b-avatar class="mr-1" variant="light-secondary">
            {{
              `${data.value.split(".")[0][0].toUpperCase()}${data.value
                .split(".")
                [data.value.split(".").length - 1][0].toUpperCase()}`
            }}
          </b-avatar>
          {{ data.value }}
        </template>
        <template #cell(Time)="data">
          {{ timer(data.value) }}
        </template>
        <template #cell(Productivity)="data">
          {{ `%${data.value.calculated}` }}
        </template>
        <template #cell(Queues)="data">
          {{ data.value.Value }}
        </template>
        <template #cell(Reason)="data">
          <b-badge
            :variant="
              reason_colors.hasOwnProperty(data.value)
                ? `light-${reason_colors[data.value]}`
                : 'light-primary'
            "
            style="width: 9em"
            >{{ data.value }}</b-badge
          >
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            :disabled="data.item.Reason != 'Available'"
            boundary="window"
            dropleft
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="startInternalCall(data.item)">
              <feather-icon icon="PhoneCallIcon" />
              <span class="align-middle ml-50">{{ lang("t_call") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-modal> -->
    <b-modal
      style="min-height: 400px"
      id="modal-settings"
      :title="lang('t_settings')"
      centered
    >
      <b-form-group>
        <v-select
          @input="on_language_change"
          class="mb-3"
          v-model="selected_language"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="languages"
          :reduce="(val) => val.internal_name"
          label="display_name"
          :clearable="false"
        />
      </b-form-group>
    </b-modal>
    <b-modal
      body-class="px-0 py-0"
      v-model="modal_change_password"
      centered
      hide-header
      hide-footer
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <security-component></security-component>
    </b-modal>
  </div>
</template>

<script>
var beep = new Audio("sounds/beep.mp3");

window.makeGuid = function (length = 16, blockSize = 16, numberBase = 16, trim = true) {
  let str = '';
  for (let i = 0; i < length; i++) {
    str += `${(((1 + Math.random()) * blockSize) | 0).toString(numberBase).substring(1)}`;
  }
  return trim ? str.substr(0, length).toUpperCase() : str.toUpperCase();
};

import {
  BDropdownForm, BMedia, BMediaBody,
  BFormGroup, BFormInput, BTable, BBadge, BModal, BButtonGroup, BDropdown, BImg, BFormCheckbox, VBTooltip, BButton, BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BRow,
} from 'bootstrap-vue'
import DarkToggler from './DarkToggler.vue'
import NotificationDropdown from './NotificationDropdown.vue'
import Ripple from 'vue-ripple-directive'
import { $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig'
import useAppCustomizer from './useAppCustomizer'
import { computed } from '@vue/composition-api'
import Tracker from '@openreplay/tracker';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import securityComponent from '../../views/CustomComponents/Security.vue'
export default {
  data() {
    return {
      all_statuses: [],
      modal_change_password: false,
      sendJoinQueue: false,
      status: [],
      selected_status: {},
      status_color: '',
      interval: {},
      statu_timer: 0,
      statu_timer_text: '00:00',
      selected_language: window.localStorage.getItem('language') || "tr",
      languages: [
        { internal_name: 'en', display_name: 'English' },
        { internal_name: 'tr', display_name: 'Türkçe' },
      ],
      lang: _l(window.localStorage.getItem('language') || "tr"),
      logo_sq: globalThis.env.MENU_LOGO_SQ,
      username: 'burak.ersever',
      productivity: '%87',
      selected_button: '',
      theme_config: $themeConfig,
      qm_socket: null,
      socket: {},
      live_info: {
        QueueStatistics: new Map(),
        AgentStatistics: new Map(),
      },
      all_queues: [],
      queues: [],
      selected_queues: [],
      user_agent: null,
      is_registered: false,
      first_connect: false,
      interaction_queues: {},
      status_categories: [],
      agent_list: [],
      modal_agent_list: false,
      start_time: new Date(),
      agent_list_fields: [
        { key: 'Username', label: globalThis._lang('t_userName'), sortable: true },
        { key: 'Reason', label: globalThis._lang('t_status'), sortable: true },
        { key: 'Time', label: globalThis._lang('t_time'), sortable: true },
        { key: 'actions', label: '', sortable: false, thStyle: { width: '5vh' } },
        // { key: 'Productivity', label: globalThis._lang('t_productivity'), sortable: true },
        // { key: 'AcceptedCalls', label: globalThis._lang('t_answered'), sortable: true },
        // { key: 'MissedCalls', label: globalThis._lang('t_rInboundFail'), sortable: true },
        // { key: 'AcceptedDialerCalls', label: globalThis._lang('t_rDialerSuccess'),sortable:true },
        // { key: 'MissedDialerCalls', label: globalThis._lang('t_rDialerFail'),sortable:true },
        // { key: 'SuccessfulOutboundCalls', label: globalThis._lang('t_successfullCall'), sortable: true },
        // { key: 'FailedOutboundcalls', label: globalThis._lang('t_unsuccessfullCall'), sortable: true },
      ],
      colors: {
        'Avail': 'success',
        'Lunch': 'warning',
        'Break': 'warning',
        'In Call - Outbound': 'info',
        'In Call - Inbound': 'info',
        'In Call - Dialer': 'info',
        'In Call - Internal': 'info',
        'ACW': 'info',
        'Ringing': 'info',
        'Offline': 'secondary',
      },
      reason_colors: {
        "Available": "success",
        "Lunch": "warning",
        "Break": "warning",
        "In Call - Outbound": "info",
        "In Call - Inbound": "info",
        "In Call - Dialer": "info",
        "In Call - Transfer": "info",
        "In Call - Internal": "info",
        "In Text": "info",
        "Awaiting Transfer": "info",
        "ACW": "secondary",
        "Calling": "info",
        "Ringing": "info",
        "Offline": "danger",
      },
      searchQuery: '',
      other_permission: [],
      menu_list: [],
      selected_project: {},
      tmp_uniq_id: '',
      channelInterval: {},
      name: '',
      surname: '',
      role: '',
      notifications: [],

    }
  },
  components: {
    BMediaBody,
    BMedia,
    BDropdownForm,
    securityComponent,
    vSelect, BFormGroup,
    BTable, BBadge, BFormInput,
    BModal,
    BButtonGroup,
    BRow,
    BButton,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg, BFormCheckbox,

    // Navbar Components
    DarkToggler,
    NotificationDropdown,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  setup() {
    // App Name
    const { menu } = $themeConfig.layout.menu;
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    const {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,
    } = useAppCustomizer()
    return {
      menu,
      skin,
      isDark,
      isNavMenuHidden
    }
  },
  methods: {
    loadCrmPage() {
      return new Promise((resolve) => {
        if (this.$router.currentRoute.name !== 'crm') {
          this.$router.replace('/crm').then(() => {
            this.waitForCrmPageToBeReady().then(resolve);
          });
        } else {
          this.waitForCrmPageToBeReady().then(resolve);
        }
      });
    },
    waitForCrmPageToBeReady() {
      return new Promise((resolve) => {
        Vue.nextTick(() => {
          setTimeout(() => {
            resolve();
          }, 2000); // CRM sayfasının tamamen yüklenmesi ve mounted işlemlerinin tamamlanması için yeterli süreyi bekliyoruz
        });
      });
    },
    handleInteractionError(data, error) {
      globalThis._voyce.$qm.emit('qmgr:AcceptInteraction', {
        queue: data.Queue || 'undefined',
        interactionId: data.InteractionID || '',
      });
      globalThis._voyce.$qm.emit('qmgr:FinishInteraction', {
        queue: data.Queue || 'undefined',
        interactionId: data.InteractionID || '',
      });
      globalThis._voyce.$qm.emit('qmgr:FinalizeInteraction', {
        queue: data.Queue || '',
        interactionId: data.InteractionID || '',
        verdict: 'FaultyCall',
        details: {
          note: 'Bağlantı Hatası',
          action_date: '',
          last_finish_code: false,
          color: '',
          is_click_to_call: false,
          finalize_reason: '',
          category_status: '',
          error: error.message ?? '',
        },
      });
    },

    readNotifications() {
      for (const item of this.notifications) {
        item.Read = true;
      }
    },

    beforeUnload(e) {
      const message = 'Sayfadan çıkmak istediğinize emin misiniz?';
      e.returnValue = message;
      return message;
    },
    sendSocketDisconnectEvent: async function (payload) {
      var response = (await this.$http_in.post(`/agent/v1/SocketDisconnect`, payload)).data;
    },

    assignee_conversation: async function (id) {
      var response = (await this.$http_in.put(`/text-channel/v1/AcceptConversation/${id}`)).data;

      if (this.$router.currentRoute.name == 'external_chat') {
        if (globalThis.external_chat) {
          await globalThis.external_chat.get_conversations();
          globalThis.external_chat.conversation = null;
        }
      } else {
        this.selected_button = 'external_chat';
        this.$router.replace('/external_chat');
      }
    },

    on_language_change() {
      globalThis.selectLang(this.selected_language);
    },
    get_live_agent: async function () {
      // return new Promise(async (resolve, reject)=>{
      var response = (await this.$http_in.get(`qmgr/v1/LiveAgent`)).data;
      this.agent_list = response.filter(e => e && e.Status != 'Offline' && e.Username != globalThis.user.username);
      if (globalThis.LayoutContentRendererDefault) {
        globalThis.LayoutContentRendererDefault.online_users = response.filter(e => e && e.Status != 'Offline');
        globalThis.LayoutContentRendererDefault.chat_users = response.filter(e => e && e.Status != 'Offline').map(e => { return { nickname: `${e.Username}@${e.Domain}` } });

      }
      // console.log("agent123", this.agent_list);
      return;
      //   setTimeout(()=>{
      //     resolve();
      //   }, 300)
      // })

    },
    // register_change() {
    //   if (this.is_registered) {
    //     this.unregister();
    //   } else {
    //     this.login();
    //   }

    // },
    on_status_change(val) {
      // this.status = this.status.filter(e => e.display_name != 'Offline');
      // this.GetStatusColor(this.status_categories.find(e => e._id == val.status_category_id).internal_name);
      // this.selected_status = val;
      // this.statu_timer = 0;
      // clearInterval(this.interval);
      // this.interval = setInterval(() => {
      //   this.statu_timer += 1000;
      //   this.statu_timer_text = this.msToTimeString(this.statu_timer);
      // }, 1000);
      // console.log("on_status_change", val);

      if (val.display_name == 'Available') {
        globalThis._voyce.$qm.emit('qmgr:Unpause', {});

      }
      else {
        globalThis._voyce.$qm.emit('qmgr:Pause', {
          reason: val.display_name,
        });
      }

    },
    GetStatus: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/Status`)
      ).data;

      this.status = [];
      this.all_statuses = [];
      this.status_categories = response.categories;
      globalThis.status_categories = response.categories;
      globalThis.statuses = response.status;
      let _status = globalThis.permissions["status"]
      for (const row of response.status) {
        if (_status.includes(row['_id'])) {
          this.status.push(row);
          this.all_statuses.push(row);
        }
      }
      this.selected_status = response.status.find((e) => e.display_name == 'Offline');
      // this.GetStatusColor(this.status_categories.find(e => e._id == this.selected_status.status_category_id).internal_name);
    },
    GetStatusColor(category) {

      switch (category) {
        case 'Available':
          this.status_color = 'flat-success';
          break;
        case 'Offline':
          this.status_color = 'flat-danger';
          break;
        case 'Break':
          this.status_color = 'flat-warning';
          break;
        case 'In Call':
          this.status_color = 'flat-info';
          break;
        default:
          this.status_color = 'flat-primary';
          break;
      }
    },

    GetStatusColorByReason(internal_name) {
      switch (internal_name) {
        case 'Available':
          this.status_color = 'flat-success';
          break;
        case 'Lunch':
          this.status_color = 'flat-warning';
          break;
        case 'Break':
          this.status_color = 'flat-warning';
          break;
        case 'In Call - Internal':
          this.status_color = 'flat-info';
          break;
        case 'In Call - Outbound':
          this.status_color = 'flat-info';
          break;
        case 'In Call - Inbound':
          this.status_color = 'flat-info';
          break;
        case 'In Call - Dialer':
          this.status_color = 'flat-info';
          break;
        case 'In Call - Transfer':
          this.status_color = 'flat-info';
          break;
        case 'In Text':
          this.status_color = 'flat-info';
          break;
        case 'Awaiting Transfer':
          this.status_color = 'flat-info';
          break;
        case 'ACW':
          this.status_color = 'flat-secondary';
          break;
        case 'Calling':
          this.status_color = 'flat-info';
          break;
        case 'Ringing':
          this.status_color = 'flat-info';
          break;
        case 'Offline':
          this.status_color = 'flat-danger';
          break;
        default:
          this.status_color = 'flat-primary';
          break;
      }
    },
    change_status(key, value) {
      if (key == "status_category_id") {
        this.selected_status = this.all_statuses.find((e) => e.status_category_id == value);

      }
      else if (key == "display_name") {
        this.selected_status = this.all_statuses.find((e) => e.display_name == value);

      } else {

      }

    },

    msToTimeString(ms) {
      // console.log("msToTimeString", ms);
      let seconds = Math.floor((ms) % 60);
      let minutes = Math.floor(ms / 60);

      seconds = seconds < 10 ? '0' + seconds : seconds;
      minutes = minutes < 10 ? '0' + minutes : minutes;

      return `${minutes}:${seconds}`;
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      // localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      globalThis.qm_token = '';

      // Remove userData from localStorage
      // localStorage.removeItem('userData')

      // Reset ability
      // this.$ability.update(initialAbility)

      // Redirect to login page
      location.reload();

      this.$router.push({ name: 'auth-login' })
    },
    login() {
      // console.log(globalThis.register_info);
      if (!globalThis.vSip) {
        globalThis.vSip = new VoyceSIP(`${globalThis.env["SOCKET_PROTOCOL"]}://${globalThis.env["SIP_URL"]}`, globalThis.register_info.sip_host, globalThis.register_info.sip_password);
        this.user_agent = globalThis.user_agent;
        this.user_agent.on('connecting', function (e) {
          console.log("connecting", e);
        });
        this.user_agent.on('disconnected', function (e) {
          v_navbar.is_registered = false;
          console.log("disconnected", e);
          // v_navbar.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: `Uyarı`,
          //     icon: 'BellIcon',
          //     text: "Medya bağlatınız koptuğu için aktif çağrı sonlandırıldı.",
          //     variant: 'warning',
          //   },
          // });
          if (globalThis.LayoutContentRendererDefault.interaction["1"]) {
            globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line].terminate();
            globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;
          }
        });
        this.user_agent.on('registered', function (e) { v_navbar.is_registered = true; console.log("registered", e) });
        this.user_agent.on('unregistered', function (e) {
          try {
            globalThis.v_navbar.sendSocketDisconnectEvent({ reason: 'unregistered', type: 'unregistered' });

          } catch (error) {

          }

          v_navbar.is_registered = false;
          console.log("unregistered", e);
          // v_navbar.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: `Uyarı`,
          //     icon: 'BellIcon',
          //     text: "Medya bağlatınız koptuğu için aktif çağrı sonlandırıldı.",
          //     variant: 'warning',
          //   },
          // });
          if (globalThis.LayoutContentRendererDefault.interaction["1"]) {
            globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line].terminate();
            globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;
          }
        });
        this.user_agent.on('registrationFailed', function (e) { v_navbar.is_registered = false; console.log("registrationFailed", e); });

      }

    },
    unregister() {
      var options = {
        all: true
      };

      this.user_agent.unregister(options);
    },
    changeMenuType() {
      // console.log("changeMenuType", this.isNavMenuHidden);
      this.isNavMenuHidden = !this.isNavMenuHidden;
    },

    GetAbandonCalls: async function () {
      var response = (
        await this.$http.post(`/LiveWatchAbandonCalls`, { "selected_queues": this.selected_queues }, {
          headers: {
            authorization: globalThis.token,
          },
        })
      ).data;

      for (const row of response) {
        let _tmp_object = {};
        if (!this.live_info.QueueStatistics.has(row.Queue)) {
          this.live_info.QueueStatistics.set(row.Queue, { CallHistory: new Map() });
        }
        _tmp_object = this.live_info.QueueStatistics.get(row.Queue);
        // console.log("test", _tmp_object);
        if (_tmp_object.CallHistory == undefined) {
          _tmp_object.CallHistory = new Map();
        }
        _tmp_object.CallHistory.set(row.Channel, row);
        _tmp_object.AbandonCounts =
          _tmp_object.AbandonCounts == undefined ? 1 : parseInt(_tmp_object.AbandonCounts) + 1;

        this.live_info.QueueStatistics.set(row.Queue, _tmp_object);
      }
    },
    formatedTimestamp: function () {
      const d = new Date();
      const date = d.toISOString().split('T')[0];
      const time = d.toTimeString().split(' ')[0];
      return `${date} ${time}`;
    },
    GetProjects: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/Project`)
      ).data;


      globalThis.projects = response;
      this.selected_project = response.find(e => e.internal_name == globalThis.user.selected_project);

    },
    GetQueues: async function () {
      var response = (
        await this.$http_in.get(`agent/v1/Queue`)
      ).data;

      this.queues = response;
      globalThis.queues = response;
    },
  },
  computed: {
    test_status() {
      return this.$store.state.app.user_state;
    }
  },
  watch: {
    test_status(newval, oldval) {
      this.change_status("display_name", newval);
      console.log("test_status", this.selected_status);
      this.GetStatusColor(this.status_categories.find(e => e._id == this.selected_status.status_category_id).internal_name);
      this.statu_timer = 0;
      clearInterval(this.interval);
      this.start_time = new Date().getTime();

      this.interval = setInterval(() => {
        let current_time = new Date().getTime();
        this.statu_timer = Math.round((current_time - this.start_time) / 1000);
        this.statu_timer_text = this.msToTimeString(this.statu_timer);
        // document.title = `${this.username.split('@')[1]} | ${data.Reason}: ${this.statu_timer_text}`;
      }, 1000);
    }
  },
  beforeDestroy() {
    try {
      // localStorage.removeItem('spvQueues');
      // localStorage.removeItem('dialer_cache');
      // window.removeEventListener('beforeunload', this.beforeUnload);

    } catch (error) {
      console.log(error);
    }
    this.qm_socket.emit(
      'qmgr:LeaveQueue', { queues: globalThis.user.selected_queues });

    // Get a reference to the last interval + 1
    const interval_id = window.setInterval(function () { }, Number.MAX_SAFE_INTEGER);

    // Clear any timeout/interval up to that id
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
    this.qm_socket.removeAllListeners();
    this.qm_socket.disconnect();
    this.qm_socket = null;
    globalThis.user_agent.stop();
  },

  mounted: async function () {
    if (globalThis.user.status == 'new') {
      this.modal_change_password = true;
    }

    if (Notification.permission !== 'granted') {
      Notification.requestPermission();

    }
    // window.addEventListener('beforeunload', this.beforeUnload);

    if (globalThis.qm_token == '') {
      this.$router.push({ name: 'auth-login' })
      return;
    }


    // console.log("globalThis.qm_token", globalThis.qm_token);
    if (this.qm_socket != null) {
      this.qm_socket.removeAllListeners();
      this.qm_socket.disconnect();
      this.qm_socket = null;
    }
    this.qm_socket = new this.$ws(`${globalThis.env["PROTOCOL"]}://${globalThis.env["GW_URL"]}`, {
      forceNew: true,
      autoConnect: false,
      extraHeaders: {
        Authorization: `Bearer ${globalThis.qm_token}`,
      },
    });

    this.qm_socket.on('System', (data) => {
      data = JSON.parse(data);
      if (data.Type == 'Error') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `İkaz`,
            icon: 'BellIcon',
            text: "Oturumunuz başka bir yerde açık olduğu için sonlandırılmıştır.",
            variant: 'warning',
          },
        })
        this.logout();
      }
    });

    this.qm_socket.connect();


    // console.log("globalThis.user.selected_queues", globalThis.user.selected_queues);
    this.qm_socket.on('connect', async () => {
      globalThis._voyce.$qm = this.qm_socket;
      console.log("qm_socket connected");
      this.first_connect = true;
      globalThis.LayoutContentRendererDefault.qm_socket_connected = true;

      if (this.sendJoinQueue == false) {
        this.qm_socket.emit(
          'qmgr:JoinQueue',
          { queues: globalThis.user.selected_queues }
        );
        this.sendJoinQueue = true;
      }


      this.get_live_agent();
      this.login();
      // this.register_change();



      globalThis.qm_status_watcher.emit("connected");
    });



    if (this.all_statuses.length == 0) {
      this.GetStatus();
      this.statu_timer = 0;
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.statu_timer += 1000;
        this.statu_timer_text = this.msToTimeString(this.statu_timer);
        // console.log(this.test_status);
      }, 1000);
    }
    this.GetProjects();
    this.GetQueues();

    this.isNavMenuHidden = true;
    let menus = ['spv_dashboard', 'appointments', 'goal_management', 'dialer', 'reports', 'sms', 'system_management', 'operation_management', 'settings', 'wallboard', 'dashboard', 'alarm', 'mail', 'quality', 'scheduler']
    this.menu_list = globalThis.permissions["menu"];
    for (const item of menus) {
      if (this.menu_list.includes(item)) {
        this.isNavMenuHidden = false;
        break;
      }
    }
    this.other_permission = globalThis.permissions["other"] || [];

    this.username = globalThis.username;
    this.name = globalThis.user.name;
    this.surname = globalThis.user.surname;
    let _role = globalThis.permissions.menu.includes('spv_dashboard') ? 'supervisor' : 'agent';
    _role = globalThis.permissions.menu.includes('system_management') ? 'admin' : _role
    this.role = globalThis.user.role ?? _role;

    window.v_navbar = this;

    // this.CheckPassword();
    // this.username = this.$store.state.user.username.split('@')[0];
    // this.exten = this.$store.state.agent.Exten;

    //  AgentStatusChange
    // AgentJoinedQueue
    // AgentLeftQueue
    // InteractionJoinedQueue
    // InteractionPulledBack
    // InteractionSentToAgent
    // AgentAcceptedInteraction
    // InteractionAbandoned


    this.qm_socket.on("disconnect", (reason) => {
      console.log("qm_socket disconnect", new Date(), reason);
      // globalThis.v_navbar.sendSocketDisconnectEvent({ reason: reason, type: 'qm_socket' });
      setTimeout(() => {
        if (this.qm_socket.connected != true) {
          this.sendJoinQueue = false;
          globalThis.LayoutContentRendererDefault.qm_socket_connected = false;

        }
      }, 1500);

      // v_navbar.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: `Uyarı`,
      //     icon: 'BellIcon',
      //     text: "Medya bağlatınız koptuğu için aktif çağrı sonlandırıldı.",
      //     variant: 'warning',
      //   },
      // });

      // if (globalThis.LayoutContentRendererDefault.interaction["1"]) {
      //   globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line].terminate();
      //   globalThis.LayoutContentRendererDefault.interaction[globalThis.LayoutContentRendererDefault.selected_line] = null;
      // }

      // globalThis.v_navbar.qm_socket.off("AgentRejectedInteractionTransfer");
      globalThis.v_navbar.qm_socket.off("AgentAcceptedInteractionTransfer");
      globalThis.v_navbar.qm_socket.off("AgentTransferRinging");
      globalThis.v_navbar.qm_socket.off("AgentTransferredCall");
      globalThis.v_navbar.qm_socket.off("AgentAcceptedInteraction");
      globalThis.v_navbar.qm_socket.off("InteractionJoinedQueue");
      globalThis.v_navbar.qm_socket.off("InteractionAbandoned");
      globalThis.v_navbar.qm_socket.off("InteractionRemovedFromQueue");
      globalThis.v_navbar.qm_socket.off("InteractionPulledBack");
      globalThis.v_navbar.qm_socket.off("AgentLeftQueue");
      globalThis.v_navbar.qm_socket.off("AgentLostCall");
      globalThis.v_navbar.qm_socket.off("InteractionSuccessful");
      globalThis.v_navbar.qm_socket.off("InteractionSentToAgent");
      globalThis.v_navbar.qm_socket.off("TransferSentToAgent");
      globalThis.v_navbar.qm_socket.off("ClickToCall");
      globalThis.v_navbar.qm_socket.offAny();

    });

    this.qm_socket.on('AgentJoinedQueue', (event) => {
      // console.log("AgentJoinedQueue", event);
      if (globalThis.spv_dashboard) {
        globalThis.spv_dashboard.AgentJoinedQueue(event);
      }

      if (`${event.Username}@${event.Domain}` == globalThis.username) {

        for (const item of globalThis.user.selected_queues) {
          this.interaction_queues[item] = new Map();
        }

        this.qm_socket.on('AgentAcceptedInteractionTransfer', (data) => {

          if (data.To == globalThis.user.username) {



            if (!data.InteractionID || this.tmp_uniq_id == data.InteractionID) {
              // console.log("returning", data.InteractionID)
              return;
            }
            // console.log("continuing with the flow");
            this.tmp_uniq_id = data.InteractionID;

            globalThis.LayoutContentRendererDefault.acw = data.ACW || 10;
            globalThis.LayoutContentRendererDefault.acw_timer = globalThis.LayoutContentRendererDefault.acw;
            // console.log("ACW", globalThis.LayoutContentRendererDefault.acw);
            if (this.selected_project.hasOwnProperty("integrations")) {
              // console.log("IF", this.selected_project.hasOwnProperty("integrations"), this.selected_project.integrations.trigger_popup);

              if (this.selected_project.integrations.trigger_popup && data.Extra.LocalNumber != '') {


                let dcs_url = `https://popup2.dcsyazilim.com/popup_v1?Key=${this.selected_project.integrations.key}&Password=${this.selected_project.integrations.password}&company_code=${this.selected_project.integrations.company_code}&caller_id=${data.Extra.RemoteNumber.substr(-11)}&did=${data.Extra.LocalNumber}&agent_id=${globalThis.user.integrations.dcs_id}&call_uid=${data.InteractionID}&queue=${data.Extra.Direction == 'outbound' ? data.Extra.Queue : data.Queue}&call_type=${data.Extra.Direction}&campaign=&operation=Pozitif&barcode1=brc1&barcode2=brc2&barcode3=brc3`;
                // console.log("DCS_URL", dcs_url);
                // this.$http_ex.get(dcs_url);

                this.$http_in.post(`/smgr/v1/Internal/dcs_test`, {
                  collection: `${this.selected_project.internal_name}_customers`,
                  data: {
                    ...data,
                    dcs_url
                  }
                });
              }

            }
          }
          // if (this.interaction_queues.hasOwnProperty(data.Queue)) {
          //   this.interaction_queues[data.Queue].delete(data.InteractionID);
          // }

          // if (globalThis.spv_dashboard) {
          //   globalThis.spv_dashboard.AgentAcceptedInteraction(data);
          // }

        });
        this.qm_socket.on('AgentTransferRinging', (data) => {
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.AgentTransferRinging(data);
          }

        })

        this.qm_socket.on('AgentTransferredCall', (data) => {
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.AgentTransferredCall(data);
          }

        });
        this.qm_socket.on('AgentAcceptedInteraction', async (data) => {

          // console.log("AgentAcceptedInteraction AGENT", data);

          if (data.Agent == globalThis.user.username) {
            globalThis.LayoutContentRendererDefault.interaction_length = 2;


            if (data.InteractionType == 'Text') {
              await this.assignee_conversation(data.InteractionID);
              return;
            }
            if (data.Extra.Direction == 'dialer') {

            }

            if (!data.InteractionID || this.tmp_uniq_id == data.InteractionID) {
              // console.log("returning", data.InteractionID)
              return;
            }
            // console.log("continuing with the flow");
            this.tmp_uniq_id = data.InteractionID;

            globalThis.LayoutContentRendererDefault.acw = data.ACW || 10;
            globalThis.LayoutContentRendererDefault.acw_timer = globalThis.LayoutContentRendererDefault.acw;
            // globalThis.LayoutContentRendererDefault.contact_number = data.Extra.RemoteNumber.substr(-11);
            // console.log("ACW", globalThis.LayoutContentRendererDefault.acw);
            if (this.selected_project.hasOwnProperty("integrations")) {
              // console.log("IF", this.selected_project.hasOwnProperty("integrations"), this.selected_project.integrations.trigger_popup);

              if (this.selected_project.integrations.trigger_popup && data.Extra.LocalNumber != '') {

                console.log("DCS TEST");
                let dcs_url = `https://popup2.dcsyazilim.com/popup_v1?Key=${this.selected_project.integrations.key}&Password=${this.selected_project.integrations.password}&company_code=${this.selected_project.integrations.company_code}&caller_id=${data.Extra.RemoteNumber.substr(-11)}&did=${data.Extra.LocalNumber}&agent_id=${globalThis.user.integrations.dcs_id}&call_uid=${data.InteractionID}&queue=${data.Extra.Direction == 'outbound' ? data.Extra.Queue : data.Queue}&call_type=${data.Extra.Direction}&campaign=&operation=Pozitif&barcode1=brc1&barcode2=brc2&barcode3=brc3`;
                // console.log("DCS_URL", dcs_url);
                // this.$http_ex.get(dcs_url);

                this.$http_in.post(`/smgr/v1/Internal/dcs_test`, {
                  collection: `${this.selected_project.internal_name}_customers`,
                  data: {
                    ...data,
                    dcs_url
                  }
                });
              }

            }
            // if (data.Domain == 'nd') {
            //   let url = `https://www.networkdry.com/temsilci_paneli/site/getmsisdnuser/?agent=${data.Agent}&msisdn=${data.Extra.RemoteNumber}&callid=${data.InteractionID}`;
            //   window.open(url, '_blank');

            // }
          }
          if (this.interaction_queues.hasOwnProperty(data.Queue)) {
            this.interaction_queues[data.Queue].delete(data.InteractionID);
          }

          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.AgentAcceptedInteraction(data);
          }

        });

        this.qm_socket.on('InteractionJoinedQueue', (data) => {
          // console.log("pano", "InteractionJoinedQueue");
          // console.log("this.interaction_queues.hasOwnProperty(data.Queue)", this.interaction_queues.hasOwnProperty(data.Queue));

          if (globalThis.user.selected_queues.includes(data.Queue)) {
            if (!this.interaction_queues.hasOwnProperty(data.Queue)) {
              this.interaction_queues[data.Queue] = new Map();
            }
            this.interaction_queues[data.Queue].set(data.InteractionID, data);
          }
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.InteractionJoinedQueue(data);
          }
          if (globalThis.wallboard) {
            globalThis.wallboard.InteractionJoinedQueue(data);
          }


        });
        this.qm_socket.on('InteractionAbandoned', (data) => {
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.InteractionAbandoned(data);
          }

          if (globalThis.wallboard) {
            globalThis.wallboard.InteractionAbandoned(data);
          }
          if (this.interaction_queues.hasOwnProperty(data.Queue)) {
            this.interaction_queues[data.Queue].delete(data.InteractionID);
          }

        });
        this.qm_socket.on('InteractionRemovedFromQueue', (data) => {
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.InteractionRemovedFromQueue(data);
          }

          if (this.interaction_queues.hasOwnProperty(data.Queue)) {
            this.interaction_queues[data.Queue].delete(data.InteractionID);
          }
        });
        this.qm_socket.on('InteractionPulledBack', (data) => {


        });
        this.qm_socket.on('AgentLeftQueue', (data) => {
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.AgentLeftQueue(data);
          }


        });

        this.qm_socket.on('AgentLostCall', (data) => {
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.AgentLostCall(data);
          }
          if (data.Username == globalThis.user.username) {

            globalThis.LayoutContentRendererDefault.ring_audio.pause();
            // globalThis.LayoutContentRendererDefault.modal_incoming = false;
            this.$swal.close();
          }
        });
        this.qm_socket.on('InteractionSuccessful', (data) => {
          if (this.interaction_queues.hasOwnProperty(data.Queue)) {
            this.interaction_queues[data.Queue].delete(data.InteractionID);
          }
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.InteractionSuccessful(data);
          }

          if (data.Agent == globalThis.user.username) {
            console.log("8", "clearInterval");

            let _record = globalThis.statisticts.ch_data.find(e => e.interaction_id == data.InteractionID);
            if (_record) {
              _record.ConnectedLength = globalThis.statisticts.toHHMMSS(Math.round(data.Extra.ConnectedLength / 1000));
            }
            globalThis.LayoutContentRendererDefault.interaction_length = 1;
            // clearInterval(globalThis.LayoutContentRendererDefault.acw_interval);


            if (data.Result == "Blacklist") {
              globalThis.LayoutContentRendererDefault.modal_finish_code = false;
              clearTimeout(this.channelInterval);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Bilgilendirme`,
                  icon: 'BellIcon',
                  text: "Aradığınız numara kara listede",
                  variant: 'warning',
                },
              })

            }

            globalThis.LayoutContentRendererDefault.current_call_info = null;
            globalThis.statisticts.get_live_agent();

            if (data.Result == "No Action") {
              globalThis.statisticts.get_ch();
            }
          }
        });


        this.qm_socket.on('AgentFinalizedInteraction', (data) => {
          if (data.Username == globalThis.user.username) {
            console.log("globalThis.statisticts.ch_data", globalThis.statisticts.ch_data);
            let _record = globalThis.statisticts.ch_data.find(e => e.interaction_id == data.InteractionID);
            console.log("_record", _record);

            if (_record) {
              _record.ACWLength = globalThis.statisticts.toHHMMSS(data.PrevStatusLength);
            }


            if (globalThis.crm && this.$router.currentRoute.name == 'crm') {
              setTimeout(() => {
                globalThis.crm.refreshCustomerDetails();
              }, 1000);

            }
            localStorage.setItem("agent_statisticts", JSON.stringify(globalThis.statisticts.$data));

          }
        });
        this.qm_socket.on('InteractionSentToAgent', async (data) => {
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.InteractionSentToAgent(data);
          }

          if (data.Agent == globalThis.user.username) {
            try {
              globalThis.LayoutContentRendererDefault.current_call_info = data;

              globalThis.LayoutContentRendererDefault.current_call_info.speech = [];


              if (data.InteractionType == 'Text') {
                globalThis.LayoutContentRendererDefault.openIncomingModal();
                return;
              } else {
                if (globalThis.LayoutContentRendererDefault.hold_interval) {
                  clearInterval(globalThis.LayoutContentRendererDefault.hold_interval);
                  globalThis.LayoutContentRendererDefault.hold_interval = null;
                }
                globalThis.LayoutContentRendererDefault.hold_time = 0;
              }

              if (data.Extra.IvrVariables && data.Extra.IvrVariables.length > 0) {
                for (const item of data.Extra.IvrVariables) {
                  if (item[0] == 'X-TransferNote') {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: `Transfer Not`,
                        icon: 'BellIcon',
                        text: item[1],
                        variant: 'warning',
                      },
                    });
                  }
                  globalThis.LayoutContentRendererDefault.dtmf_list.push(`${item[0]} = ${item[1]}`);
                }
              }

              let tmp_queue = data.Extra.Direction == 'outbound' ? data.Extra.Queue : data.Queue;
              globalThis.LayoutContentRendererDefault.dtmf_list.push(`${globalThis._lang('t_queue')} = ${globalThis.queues.find(e => e.internal_name == tmp_queue) ? globalThis.queues.find(e => e.internal_name == tmp_queue).display_name : tmp_queue}`);
              let __queue;
              if (data.Extra.Direction == 'outbound' || data.InteractionType == 'Chanspy') {
                globalThis.LayoutContentRendererDefault.accept_interaction();
              } else {
                if (tmp_queue.includes('personal:')) {
                  __queue = {
                    internal_name: data.Queue,
                    display_name: data.Queue,
                    auto_answer: false,
                  };
                } else {
                  __queue = this.queues.find(e => e.internal_name == tmp_queue || e.display_name == tmp_queue);
                }

                if (data.Extra.Direction == 'dialer' && __queue && __queue.auto_answer) {
                  beep.play();
                  globalThis.LayoutContentRendererDefault.accept_interaction();
                } else {
                  if (data.Extra.Direction == 'inbound' && __queue && __queue.auto_answer) {
                    beep.play();
                    globalThis.LayoutContentRendererDefault.accept_interaction();
                  } else {
                    globalThis.LayoutContentRendererDefault.openIncomingModal();
                  }
                }

                if (!globalThis.LayoutContentRendererDefault.is_window_active && ['dialer', 'inbound'].includes(data.Extra.Direction)) {
                  if (Notification.permission === 'granted') {
                    var notification = new Notification('Voyce Çağrı Bildirimi', {
                      icon: 'https://live.ctcomm.voyce.cc/logo.png',
                      body: this.other_permission.includes("mask_remote_number") ? globalThis.maskPhoneNumber(data.Extra.RemoteNumber.slice(-10)) : data.Extra.RemoteNumber.slice(-10),
                    });
                  }
                }
              }

              if (this.other_permission.includes("crm_popup")) {
                if (data.Extra.Direction == 'outbound' && data.Extra.ContactId) {
                  return;
                }
                await this.loadCrmPage();
                if (globalThis.crm) {
                  if (![null, undefined, ''].includes(globalThis.LayoutContentRendererDefault.current_call_info.Extra.ContactId)) {
                    globalThis.crm.getCustomerContactID(globalThis.LayoutContentRendererDefault.current_call_info.Extra.ContactId);
                  } else {
                    globalThis.crm.getCustomerByPhone(globalThis.LayoutContentRendererDefault.current_call_info.Extra.RemoteNumber.slice(-10));
                  }
                }
              }

            } catch (error) {
              console.log("ERROR", error);
              this.handleInteractionError(data, error);
            }
          }
        });

        this.qm_socket.on('TransferSentToAgent', (data) => {
          // console.log("InteractionSentToAgent3", data);
          if (globalThis.spv_dashboard) {
            globalThis.spv_dashboard.TransferSentToAgent(data);
          }

          if (data.To == globalThis.user.username) {
            try {
              if (globalThis.LayoutContentRendererDefault.hold_interval) {
                clearInterval(globalThis.LayoutContentRendererDefault.hold_interval);
                globalThis.LayoutContentRendererDefault.hold_interval = null;
              }
              globalThis.LayoutContentRendererDefault.hold_time = 0;

              globalThis.LayoutContentRendererDefault.current_call_info = data;


              if (data.Extra.IvrVariables && data.Extra.IvrVariables.length > 0) {

                for (const item of data.Extra.IvrVariables) {
                  if (item[0] == 'X-TransferNote') {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: `Transfer Not`,
                        icon: 'BellIcon',
                        text: item[1],
                        variant: 'warning',
                        position: "bottom-left",
                        timeout: 8000,
                        closeOnClick: false,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.76,
                        showCloseButtonOnHover: false,
                      },
                    });
                    globalThis.LayoutContentRendererDefault.dtmf_list.push(`TransferNote= ${item[1]}`);
                  }
                }

              }

              if (data.Extra.InternalVariables) {
                for (const item of data.Extra.InternalVariables) {
                  if (item[0] && !["RemoteNumber", 'InteractionID'].includes(item[0])) {
                    globalThis.LayoutContentRendererDefault.dtmf_list.push(`${item[0]}= ${item[1]}`);

                  }
                }
              }



              if (!globalThis.LayoutContentRendererDefault.is_window_active && ['dialer', 'inbound'].includes(data.Extra.Direction)) {
                if (Notification.permission !== 'granted')
                  Notification.requestPermission();
                else {
                  var notification = new Notification('Voyce Çağrı Bildirimi', {
                    icon: 'https://live.ctcomm.voyce.cc/logo.png',
                    body: this.other_permission.includes("mask_remote_number") ? globalThis.maskPhoneNumber(data.Extra.RemoteNumber.slice(-10)) : data.Extra.RemoteNumber.slice(-10),

                  });
                }
              }



              // if (!globalThis.LayoutContentRendererDefault.current_call_info.Queue.startsWith('personal:')) {
              //   globalThis.LayoutContentRendererDefault.selected_queue = data.Extra.Direction == 'outbound' ? data.Extra.Queue : data.Queue;

              // }
              globalThis.LayoutContentRendererDefault.openTransferModal();
              globalThis.LayoutContentRendererDefault.modal_transfer = true;

              if (this.other_permission.includes("crm_popup")) {
                if (!globalThis.crm) {
                  this.selected_button = 'crm';
                  this.$router.replace('/crm');
                }

                if (this.$router.currentRoute.name == 'crm') {
                  if (globalThis.LayoutContentRendererDefault.current_call_info != null && globalThis.v_navbar.other_permission.includes("crm_popup")) {
                    globalThis.crm.getCustomerByPhone(globalThis.LayoutContentRendererDefault.current_call_info.Extra.RemoteNumber.slice(-10));
                  }
                } else {
                  this.selected_button = 'crm';
                  this.$router.replace('/crm');
                  if (globalThis.crm) {
                    setTimeout(() => {
                      globalThis.crm.getCustomerByPhone(globalThis.LayoutContentRendererDefault.current_call_info.Extra.RemoteNumber.slice(-10));
                    }, 2000);
                  }
                }
              }

              setTimeout(() => {
                if (globalThis.LayoutContentRendererDefault.modal_transfer) {
                  globalThis.LayoutContentRendererDefault.transfer_reject_interaction();
                }
              }, 15000);

            } catch (error) {
              console.log("ERROR", error);

              globalThis._voyce.$qm.emit('qmgr:AcceptInteraction', {
                queue: data.Queue || 'undefined',
                interactionId: data.InteractionID || '',
              });


              globalThis._voyce.$qm.emit('qmgr:FinishInteraction', {
                queue: data.Queue || 'undefined',
                interactionId: data.InteractionID || '',
              });

              globalThis._voyce.$qm.emit('qmgr:FinalizeInteraction', {
                queue: data.Queue || '',
                interactionId: data.InteractionID || '',
                verdict: 'globalThis.crm',
                details: {
                  note: 'Bağlantı Hatası',
                  action_date: '',
                  last_finish_code: false,
                  color: '',
                  is_click_to_call: false,
                  finalize_reason: '',
                  category_status: '',
                  error: error.message ?? ''
                },
              });

            }


          }


          // globalThis.crm.get_customer_search_term = globalThis.LayoutContentRendererDefault.current_call_info.Extra.RemoteNumber.slice(-10);
          // globalThis.crm.getCustomerBySearch();

        });


        this.qm_socket.on('ClickToCall', (data) => {
          if (data.Username == globalThis.user.username && globalThis.LayoutContentRendererDefault.current_call_info == null) {
            if (data.Queue) {
              globalThis.LayoutContentRendererDefault.selected_queue = data.Queue;

            }
            if (data.Classification) {
              globalThis.LayoutContentRendererDefault.selected_dial_plan = data.Classification;

            }
            globalThis.LayoutContentRendererDefault.preview_call_request(data.RemoteNumber);
            if (globalThis.LayoutContentRendererDefault.current_call_info) {
              globalThis.LayoutContentRendererDefault.current_call_info.is_click_to_call = true;
            }
          }
        });


        this.qm_socket.onAny((evt, args) => {
          console.log("catchAll", evt, args);
        });
      }




    });


    this.qm_socket.on('AgentStatusChange', (data) => {
      // console.log("AgentStatusChange AGENT", data);
      if (globalThis.spv_dashboard) {
        globalThis.spv_dashboard.AgentStatusChange(data);
      }


      if (data.Status && data.Status == "NotInQueue") {
        return;
      }


      let __user = this.agent_list.find(e => e.Username == data.Username)
      if (__user) {
        __user.Reason = data.Reason;
        __user.Status = data.Status;
      };


      // this.agent_list = [...this.agent_list];
      Vue.set(this, "agent_list", this.agent_list);



      if (data.Username + "@" + data.Domain == globalThis.username) {
        if (data.Reason == 'Not Answering') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: globalThis._lang('t_notAnsweringCall'),
              icon: 'bi-exclamation-triangle',
              text: globalThis._lang('t_notAnsweringCallDesc'),
              variant: 'danger',
            },
          },
            {
              position: 'top-center',
              timeout: 60000,
              // toastClassName: "toast-property",

            });
          var audio = new Audio("sounds/voyce_notification_02.wav");
          audio.play();

          var notification = new Notification(globalThis._lang('t_notAnsweringCall'), {
            icon: 'https://live.ctcomm.voyce.cc/logo.png',
            body: globalThis._lang('t_notAnsweringCallDesc'),
          });

          let _self = this;

          setTimeout(() => {
            if (_self.selected_status.display_name == 'Not Answering') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: globalThis._lang('t_notAnsweringCall'),
                  icon: 'bi-exclamation-triangle',
                  text: globalThis._lang('t_notAnsweringCallDesc2'),
                  variant: 'danger',
                },
              },
                {
                  position: 'top-center',
                  timeout: 36000000,
                  // toastClassName: "toast-property",

                });
              var audio = new Audio("sounds/voyce_notification_02.wav");
              audio.play();

              var notification = new Notification(globalThis._lang('t_notAnsweringCall'), {
                icon: 'https://live.ctcomm.voyce.cc/logo.png',
                body: globalThis._lang('t_notAnsweringCallDesc2'),
              });
            }
          }, 60000);

        }


        if (['In Call - Outbound', 'In Call - Inbound', 'In Call - Dialer', 'In Call - Transfer'].includes(data.Reason)) {
          if (globalThis.LayoutContentRendererDefault.current_call_info) {
            globalThis.LayoutContentRendererDefault.current_call_info = { ...globalThis.LayoutContentRendererDefault.current_call_info, is_answered: true };

          }
          console.log("IS_REGISTERED", globalThis.user_agent._registrator._registered);
        }

        let _user = this.agent_list.find(e => e.Identifier == data.Username + "@" + data.Domain);
        if (_user) {
          _user.Reason = data?.Reason || '';

        }

        if (globalThis.statisticts) {
          globalThis.statisticts.get_live_agent();
        }


        this.status = this.status.filter(e => e.display_name != 'Offline');

        const val = this.all_statuses.find(e => e.display_name == data.Reason);
        document.title = `[${this.username.split('@')[1]}] - ${data.Reason} : ${this.statu_timer_text}`
        if (val) {
          let _category = globalThis.status_categories.find(e => e._id == val.status_category_id);
          if (_category) {
            // this.GetStatusColorByReason(data.Reason);
            this.GetStatusColor(_category.display_name);
          }
        }

        this.selected_status = val;
        this.statu_timer = 0;
        clearInterval(this.interval);
        this.start_time = new Date().getTime();

        this.interval = setInterval(() => {
          let current_time = new Date().getTime();
          this.statu_timer = Math.round((current_time - this.start_time) / 1000);
          this.statu_timer_text = this.msToTimeString(this.statu_timer);
          document.title = `${this.username.split('@')[1]} | ${data.Reason}: ${this.statu_timer_text}`;
        }, 1000);
      }
    });



    globalThis.tracker = {
      setUserID: function () {
        return;
      }
    }
    if (globalThis.env.ORLY_TOKEN) {
      globalThis.tracker = new Tracker({
        projectKey: globalThis.env.ORLY_TOKEN,
        ingestPoint: "https://log.voyce.cc/ingest",
      });
      tracker.start();
    }
    globalThis.tracker.setUserID(globalThis.username);
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(function (stream) {
        console.log('You let me use your mic!')
      })
      .catch(function (err) {
        console.log('No mic for you!')
      });
  },
}
globalThis.fromBinary = function (encoded) {
  return decodeURIComponent(atob(encoded).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}
</script>
<style lang="scss">
@import "@core/scss/base/core/menu/menu-types/vertical-menu.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.dClass {
  min-width: 60vw;
}
.dClass75 {
  min-width: 75vw;
}
</style>
